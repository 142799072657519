const LoadingView = () => import('@/views/LoadingView.vue');
const HumanBacteria = () => import('@/views/HumanBacteria.vue');
const Uhbs = () => import('@/views/Uhbs.vue');
const India = () => import('@/views/India.vue');
const HumanFungi = () => import('@/views/HumanFungi.vue');
const HumanLocal = () => import('@/views/HumanLocal.vue');
const Ksgr = () => import('@/views/Ksgr.vue');
const Ksb = () => import('@/views/Ksb.vue');
const Veterinary = () => import('@/views/Veterinary.vue');
const Showcase = () => import('@/views/ShowcaseView.vue');

export default [
  {
    path: '/',
    redirect: () => {
      return { path: '/human-bacteria' };
    },
  },
  {
    path: '/human-bacteria',
    name: 'Human bacteria',
    component: HumanBacteria,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/uhbs',
    name: 'Unispital Basel',
    component: Uhbs,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/ksb',
    name: 'Kantonsspital Baden',
    component: Ksb,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/human-fungi',
    name: 'Human fungi',
    component: HumanFungi,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/human-local',
    name: 'Human local',
    component: HumanLocal,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/ksgr',
    name: 'Kantonsspital Graubünden',
    component: Ksgr,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/india',
    name: 'India',
    component: India,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/veterinary',
    name: 'Veterinary',
    component: Veterinary,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/loading',
    name: 'loading',
    component: LoadingView,
  },
  {
    path: '/showcase',
    name: 'showcase',
    component: Showcase,
  },
];
